import React, { useState, useEffect, useRef, useMemo } from "react";
import { StatusBar } from "expo-status-bar";
import {
  Animated,
  Platform,
  TextInput,
  StyleSheet,
  FlatList,
  TouchableHighlight,
  useColorScheme,
  useWindowDimensions,
} from "react-native";
import StickyContainer from "recyclerlistview/dist/reactnative/core/StickyContainer";
import AsyncStorage from "@react-native-async-storage/async-storage";
import "react-native-get-random-values";
import { useLinkTo } from "@react-navigation/native";
import Swipeable from "react-native-gesture-handler/Swipeable";
import { RectButton } from "react-native-gesture-handler";
import {
  MaterialIcons,
  MaterialCommunityIcons,
  Ionicons,
  Feather,
} from "@expo/vector-icons";
import { useMutation, gql, useSubscription } from "@apollo/client";
import { AntDesign } from "@expo/vector-icons";
import { Entypo } from "@expo/vector-icons";
import { Text, View } from "./Themed";
import Alert, { AlertRoot } from "./Alert";
import ProductItem from "./ProductItem";
import i18n from "../utils/localization";
import SwipeableProductItem from "./SwipeableProductItem";
import { Picker } from "@react-native-picker/picker";
import {
  RecyclerListView,
  DataProvider,
  LayoutProvider,
} from "recyclerlistview";
import { useActionsHeader } from "./ActionsHeader";

const FINALIZE_DELIVERY = gql`
  mutation finalizeDelivery(
    $token: String!
    $operator: ID!
    $delivery: ID!
    $deliveryToken: String!
    $paymentType: String!
  ) {
    finalizeDelivery(
      token: $token
      operator: $operator
      delivery: $delivery
      deliveryToken: $deliveryToken
      paymentType: $paymentType
    )
  }
`;

const DELIVERY_RECEIPT = gql`
  mutation deliveryReceipt(
    $token: String!
    $operator: ID!
    $delivery: ID!
    $deliveryToken: String!
  ) {
    deliveryReceipt(
      token: $token
      operator: $operator
      delivery: $delivery
      deliveryToken: $deliveryToken
    )
  }
`;

const ARCHIVE_DELIVERY = gql`
  mutation archiveDelivery(
    $token: String!
    $operator: ID!
    $delivery: ID!
    $deliveryToken: String!
  ) {
    archiveDelivery(
      token: $token
      operator: $operator
      delivery: $delivery
      deliveryToken: $deliveryToken
    )
  }
`;

const getItemLayout = (data, index) => ({
  length: 100,
  offset: (100 + 0) * index,
  index,
});
const TAKE_DELIVERY_OWNERSHIP = gql`
  mutation takeDeliveryOwnership(
    $token: String!
    $operator: ID!
    $delivery: ID!
  ) {
    takeDeliveryOwnership(
      token: $token
      operator: $operator
      delivery: $delivery
    )
  }
`;

const CREATE_DELIVERY_ORDER = gql`
  mutation createDeliveryOrder(
    $id: ID!
    $token: String!
    $operator: ID!
    $delivery: ID!
    $productIds: [ID!]!
    $quantities: [Int!]!
    $variants: [String!]!
    $capabilities: [String]!
    $comment: String
    $options: [String]!
  ) {
    createDeliveryOrder(
      id: $id
      token: $token
      operator: $operator
      delivery: $delivery
      productIds: $productIds
      quantities: $quantities
      variants: $variants
      comment: $comment
      capabilities: $capabilities
      options: $options
    ) {
      id
      operator
      status
      city
      comment
      street
      phone
      finalized
      receipt
      location
      orders {
        id
        operator
        productIds
        quantities
        variants
        options
        comment
        capabilities
        deliveryId
        timestamp
        status
      }
    }
  }
`;

const DELETE_DELIVERY_ORDER = gql`
  mutation deleteDeliveryOrder(
    $id: ID!
    $token: String!
    $operator: ID!
    $delivery: ID!
  ) {
    deleteDeliveryOrder(
      id: $id
      token: $token
      operator: $operator
      delivery: $delivery
    ) {
      id
      operator
      status
      city
      comment
      finalized
      receipt
      location
      street
      phone
      orders {
        id
        operator
        productIds
        quantities
        variants
        comment
        capabilities
        deliveryId
        timestamp
        status
      }
    }
  }
`;

const CLEAR_DELIVERY_ORDERS = gql`
  mutation clearDeliveryOrders(
    $token: String!
    $operator: ID!
    $delivery: ID!
  ) {
    clearDeliveryOrders(token: $token, operator: $operator, delivery: $delivery)
  }
`;

const SET_DELIVERY_DESTINATION = gql`
  mutation setDeliveryDestination(
    $token: String!
    $operator: ID!
    $delivery: ID!
    $city: String
    $street: String
    $comment: String
    $phone: String
    $location: ID!
  ) {
    setDeliveryDestination(
      token: $token
      operator: $operator
      delivery: $delivery
      city: $city
      street: $street
      comment: $comment
      phone: $phone
      location: $location
    )
  }
`;

const DELIVERY_SUBSCRIPTION = gql`
  subscription {
    deliveries {
      id
      operator
      status
      city
      finalized
      receipt
      comment
      street
      location
      orders {
        id
        operator
        productIds
        quantities
        comment
        deliveryId
        capabilities
        timestamp
        status
      }
    }
  }
`;

const CHANGE_DELIVERY_ORDER_STATUS = gql`
  mutation changeDeliveryOrderStatus($token: String!, $operator: ID!, $deliveryId: ID!, $id: ID!, $status: String!) {
    changeDeliveryOrderStatus(token: $token, operator: $operator, deliveryId: $deliveryId, id: $id, status: $status) {
      id
      operator
      productIds
      quantities
      variants
      comment
      deliveryId
      capabilities
      timestamp
      status
    }
  }
`;

const Jared = { width: 1920, numColumns: 1 };

export default function DeliveryOrderView({
  route,
  navigation,
  products,
  delivery,
  categories,
  locations,
}) {
  const deliveryId = route.params && route.params.deliveryId;
  const deliveryToken = route.params && route.params.deliveryToken;

  const colorScheme = useColorScheme();

  const [operatorId, setOperatorId] = useState(null);
  const [token, setToken] = useState(null);
  const [role, setRole] = useState(null);
  const [showDeliveryFields, setShowDeliveryFields] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const storedRole = await AsyncStorage.getItem("role");
        setRole(storedRole);
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const { data: tablesSubscriptionData, loading: tablesSubscriptionLoading } =
    useSubscription(DELIVERY_SUBSCRIPTION);

  const [
    finalizeDelivery,
    { finalizeTableData, finalizeTableLoading, finalizeTableError },
  ] = useMutation(FINALIZE_DELIVERY, {
    refetchQueries: ["getDeliveryDetails"],
  });

  const [
    deliveryReceipt,
    { deliveryReceiptData, deliveryReceiptLoading, deliveryReceiptError },
  ] = useMutation(DELIVERY_RECEIPT);

  const [
    archiveDelivery,
    { archiveDeliveryData, archiveDeliveryLoading, archiveDeliveryError },
  ] = useMutation(ARCHIVE_DELIVERY, {
    refetchQueries: ["getDeliveryDetails"],
  });


  
  const [
    changeDeliveryOrdersStatus,
    { changeDeliveryOrdersStatusData, changeDeliveryOrdersStatusLoading, changeDeliveryOrdersStatusError },
  ] = useMutation(CHANGE_DELIVERY_ORDER_STATUS);



  const [takeDeliveryOwnership] = useMutation(TAKE_DELIVERY_OWNERSHIP);

  const [
    createDeliveryOrder,
    { createTableOrderData, createTableOrderLoading, createTableOrderError },
  ] = useMutation(CREATE_DELIVERY_ORDER, {
    ignoreResults: true,
  });

  const [
    deleteDeliveryOrder,
    { deleteTableOrderData, deleteTableOrderLoading, deleteTableOrderError },
  ] = useMutation(DELETE_DELIVERY_ORDER, {
    ignoreResults: true,
  });

  const [
    clearDeliveryOrders,
    { clearTableOrdersData, clearTableOrdersLoading, clearTableOrdersError },
  ] = useMutation(CLEAR_DELIVERY_ORDERS, {
    refetchQueries: ["getDeliveryDetails"],
  });

  const [
    setDeliveryDestination,
    {
      setDeliveryDestinationData,
      setDeliveryDestinationLoading,
      setDeliveryDestinationError,
    },
  ] = useMutation(SET_DELIVERY_DESTINATION, {
    refetchQueries: ["getDeliveryDetails"],
  });

  const productsListRef = useRef(null);
  const ordersRef = useRef(null);

  const linkTo = useLinkTo();

  const window = useWindowDimensions();

  Jared.width = window.width;
  const [dataProvider, setDataProvider] = useState(() => {
    return new DataProvider((r1, r2) => r1 !== r2);
  });
  const [dataProvider2, setDataProvider2] = useState(() => {
    return new DataProvider((r1, r2) => r1 !== r2);
  });
  const [extendedState, setExtendedState] = useState({
    chooseVariantValue: {},
    commentValue: {},
    imageValue: {},
    optionsValue: {},
  });
  const [numColumns, setNumColumns] = useState(() => {
    let numColumns = 1;
    if (window.width > 720) {
      numColumns = 2;
    }
    if (window.width > 1000) {
      numColumns = 3;
    }
    if (window.width > 1800) {
      numColumns = 4;
    }
    return numColumns;
  });

  const [layoutProvider, setLayoutProvider] = useState(() => {
    return new LayoutProvider(
      () => {
        return "VSEL";
      },
      (type, dim) => {
        switch (type) {
          case "VSEL":
            dim.width = (Jared.width - 17) / Jared.numColumns;
            dim.height = 160;
            break;
          default:
            dim.width = 0;
            dim.heigh = 0;
        }
      }
    );
  });

  const [layoutProvider2, setLayoutProvider2] = useState(() => {
    return new LayoutProvider(
      () => {
        return "VSEL";
      },
      (type, dim) => {
        switch (type) {
          case "VSEL":
            dim.width = (Jared.width - 17) / Jared.numColumns;
            dim.height = 160;
            break;
          default:
            dim.width = 0;
            dim.heigh = 0;
        }
      }
    );
  });

  useEffect(() => {
    let numColumns = 1;
    if (window.width > 720) {
      numColumns = 2;
    }
    if (window.width > 1000) {
      numColumns = 3;
    }
    if (window.width > 1800) {
      numColumns = 4;
    }
    setNumColumns(numColumns);
    Jared.width = window.width;
    Jared.numColumns = numColumns;
  }, [window]);

  useEffect(() => {
    if (products) {
      setDataProvider(dataProvider.cloneWithRows(products));
    }
  }, [products]);

  useEffect(() => {
    if (delivery && delivery.orders) {
      setDataProvider2(dataProvider2.cloneWithRows(delivery.orders));
    }
  }, [delivery]);

  const total = delivery
    ? delivery.orders
        .map((order, i) => {
          return order.productIds
            .map((pis, pisindex) => {
              const product = products.filter((p) => p.id === pis)[0];
              const indexOfVariant = product.variants.findIndex(
                (v) => v === order.variants[pisindex]
              );

              const optionsCost = order.options ? order.options.reduce((acc, v, i) => {
                if (i % 2 == 0) {
                  const poption = product.options.filter(o => o.id === v)[0];
                  const pchoice = poption.options.filter(o => o.id === order.options[i+1])[0]
                  return acc + pchoice.prices[indexOfVariant];
                } else {
                  return acc + 0.0;
                }
              }, 0.0) : 0.0;

              return (product.prices[indexOfVariant] * order.quantities[pisindex]) + optionsCost
            })
            .reduce((a, b) => a + b, 0);
        })
        .reduce((a, b) => a + b, 0)
    : 0.0;

  useActionsHeader(
    navigation,
    { name: i18n.t('Delivery') },
    token,
    operatorId,
    deliveryId,
    [
      {
        confirm: "Are you sure you want to start delivering this order?",
        onPress: async () => {
          for (let o of delivery.orders) {
            const result = await changeDeliveryOrdersStatus({
              variables: {
                id: o.id,
                token: token,
                operator: operatorId,
                deliveryId: deliveryId,
                status: 'delivering',
              },
            });
            console.log(result)
          }
        },
        condition: delivery && delivery.orders.filter(o => o.status === 'ready').length > 0,
        icon: (
          <Entypo
            name="address"
            size={24}
            color={colorScheme === "dark" ? "white" : "black"}
          />
        ),
      },
      {
        condition:
          role === "admin" &&
          delivery &&
          !delivery.finalized &&
          (operatorId === delivery.operator || delivery.operator === null) &&
          !deliveryToken,
        confirm: "Are you sure you want to delete ALL orders?",
        onPress: async () => {
          await clearDeliveryOrders({
            variables: {
              token: token,
              operator: operatorId,
              delivery: delivery.id,
            },
          });
          linkTo("/tables/deliveries");
        },
        icon: (
          <AntDesign
            name="delete"
            size={24}
            color={colorScheme === "dark" ? "white" : "black"}
          />
        ),
      },
      {
        condition:
          delivery &&
          !delivery.finalized &&
          (operatorId === delivery.operator || delivery.operator === null) &&
          delivery.orders.length > 0 &&
          !deliveryToken,
        confirm: "Are you sure you want to finalize this delivery?",
        onPress: async () => {
          await finalizeDelivery({
            variables: {
              token: token,
              operator: operatorId,
              delivery: delivery.id,
              deliveryToken: "",
              paymentType: "cash",
            },
          });
          //linkTo("/tables/deliveries");
        },
        icon: (
          <MaterialIcons
            name="attach-money"
            size={24}
            color={colorScheme === "dark" ? "white" : "black"}
          />
        ),
      },
      {
        condition:
          delivery &&
          !delivery.finalized &&
          (operatorId === delivery.operator || delivery.operator === null) &&
          delivery.orders.length > 0 &&
          !deliveryToken,
        confirm: "Are you sure you want to finalize this delivery?",
        onPress: async () => {
          await finalizeDelivery({
            variables: {
              token: token,
              operator: operatorId,
              delivery: delivery.id,
              deliveryToken: "",
              paymentType: "card",
            },
          });
        },
        icon: (
          <MaterialIcons
            name="credit-card"
            size={24}
            color={colorScheme === "dark" ? "white" : "black"}
          />
        ),
      },
      
      {
        condition: delivery && delivery.finalized && !delivery.receipt && (operatorId === delivery.operator || delivery.operator === null) && delivery.orders.length > 0 && !deliveryToken,
        confirm: "Are you sure you want to issue the receipt?",
        onPress: () => {
          deliveryReceipt({
            variables: {
              token: token,
              operator: operatorId,
              delivery: delivery.id,
              deliveryToken: "",
            },
          })
        },
        icon: <Ionicons name="receipt-outline" size={24} color={colorScheme === "dark" ? "white" : "black"} />
      },
      {
        condition: delivery && delivery.finalized && (operatorId === delivery.operator || delivery.operator === null) && delivery.orders.length > 0 && !deliveryToken,
        confirm: 'Are you sure you want to archive this delivery?',
        onPress: async () => {
          await archiveDelivery({
            variables: {
              token: token,
              operator: operatorId,
              delivery: delivery.id,
              deliveryToken: "",
            },
          })
          
          linkTo("/tables/deliveries");
        },
        icon: <MaterialCommunityIcons name="folder-key-outline" size={24} color={colorScheme === "dark" ? "white" : "black"} />
      },
      {
        condition: true,
        icon: (
          <View
            style={{ backgroundColor: "transparent", flexDirection: "row" }}
          >
            <Text style={[{ fontSize: 18 }]}>{total.toFixed(2)}</Text>
          </View>
        ),
      },
      {
        condition:
          delivery &&
          operatorId !== delivery.operator &&
          delivery.operator !== null &&
          !deliveryToken,
        confirm: "Are you sure you want to take ownership?",
        onPress: () => {
          takeDeliveryOwnership({
            variables: {
              token: token,
              operator: operatorId,
              delivery: delivery.id,
            },
          });
        },
        icon: (
          <Feather
            name="refresh-ccw"
            size={24}
            color={colorScheme === "dark" ? "white" : "black"}
          />
        ),
      },
    ]
  );

  if (finalizeTableLoading) return "Submitting...";
  if (finalizeTableError) return `Submission error! ${error.message}`;

  if (clearTableOrdersLoading) return "Submitting...";
  if (clearTableOrdersError) return `Submission error! ${error.message}`;

  if (!deliveryId) {
    return null;
  }

  const renderProductItem = (type, item, index, extendedState) => (
    <SwipeableProductItem
      key={item.id}
      products={products}
      item={item}
      onCreate={(orderData) => {
        createDeliveryOrder({
          variables: {
            ...orderData,
            token: token || deliveryToken,
            operator: operatorId || deliveryToken,
            delivery: delivery.id,
          },
        });
        setTimeout(() => {
          ordersRef.current.scrollToOffset(
            0,
            delivery.orders.length * 160,
            true,
            true
          );
        }, 900);
      }}
      openOnTap={true}
      colorScheme={colorScheme}
      index={index}
      chooseVariantValue={extendedState.chooseVariantValue[index]}
      chooseVariant={(index, variant) => {
        const newState = {
          ...extendedState,
          chooseVariantValue: {
            ...extendedState.chooseVariantValue,
          },
        };
        newState.chooseVariantValue[index] = variant;
        setExtendedState(newState);
      }}
      optionsValue={extendedState.optionsValue[index]}
      setOptionsValue={(index, option, value) => {
        const newState = {
          ...extendedState,
          optionsValue: {
            ...extendedState.optionsValue
          }
        }
        const existing = newState.optionsValue[index] || {};
        existing[option] = value
        if (!value) {
          delete existing[option]
        }
        newState.optionsValue[index] = existing;
        setExtendedState(newState);
      }}
      imageValue={extendedState.imageValue[index]}
      setImageValue={(index, image) => {
        const newState = {
          ...extendedState,
          imageValue: {
            ...extendedState.imageValue,
          },
        };
        newState.imageValue[index] = image;
        setExtendedState(newState);
      }}
      commentValue={extendedState.commentValue[index]}
      setCommentValue={(index, comment) => {
        const newState = {
          ...extendedState,
          commentValue: {
            ...extendedState.commentValue,
          },
        };
        newState.commentValue[index] = comment;
        setExtendedState(newState);
      }}
    />
  );

  const renderItem = (type, item, index, extendedState) => {
    let swipeableRow;

    function updateRef(ref) {
      swipeableRow = ref;
    }

    return item.productIds.map((productId, index) => {
      const product = products.filter((p) => p.id === productId)[0];
      const indexOfVariant = product.variants.findIndex(
        (v) => v === item.variants[0]
      );
      const price = product.prices[indexOfVariant];
      return (
        <Swipeable
          ref={updateRef}
          useNativeAnimations={Platform.OS !== "web"}
          enableTrackpadTwoFingerGesture
          onSwipeableOpen={(direction) => {
            if (deliveryToken) {
              if (swipeableRow) {
                swipeableRow.close();
              }
              return;
            }
            if (direction === "right") {
              const index = products.findIndex(
                (product) => product.id === item.id
              );
              const product = products[index];

              setTimeout(() => {
                deleteDeliveryOrder({
                  variables: {
                    token: token,
                    operator: operatorId,
                    delivery: delivery.id,
                    id: item.id,
                  },
                });
              }, 30);

              if (swipeableRow) {
                swipeableRow.close();
              }
            }
          }}
          onSwipeableClose={(direction) => {
            console.log(`Closing swipeable to the ${direction}`);
          }}
          renderRightActions={(progress, dragX) => {
            const trans = dragX.interpolate({
              inputRange: [0, 50, 100, 101],
              outputRange: [-20, 0, 0, 1],
            });
            return (
              <RectButton
                style={{
                  alignItems: "center",
                  flex: 1,
                  justifyContent: "center",
                }}
                onPress={() => {}}
              >
                <Animated.Text
                  style={[
                    {
                      color: "white",
                      //transform: [{ translateX: trans }],
                    },
                  ]}
                >
                  {!deliveryToken && "BYE, Salut, Arrivederci, Чао, 😔"}
                </Animated.Text>
              </RectButton>
            );
          }}
        >
          <View style={{ flex: 1 }}>
            <ProductItem
              {...product}
              variant={indexOfVariant}
              itemStyle={{ padding: 15 }}
              selectedOptions={item.options}
              comment={item.comment}
              colorScheme={colorScheme}
              onRemove={(options) => {}}
              index={index}
            />
          </View>
          {/* </TouchableHighlight> */}
        </Swipeable>
      );
    })[0];
  };

  const renderStickyItem = (type, item, index) => (
    <FlatList
      horizontal
      showsHorizontalScrollIndicator={false}
      style={{ backgroundColor: colorScheme === "light" ? "white" : "black" }}
      data={categories}
      keyExtractor={(item, index) => item.id}
      renderItem={({ item, index }) => (
        <TouchableHighlight
          onPress={() => {
            if (productsListRef.current) {
              let firstIndex = 0;
              try {
                firstIndex = Math.floor(
                  products.findIndex((p) => p.category === item.id) / numColumns
                );
                //productsListRef.current.scrollToIndex({ viewOffset: 65, animated: true, index: firstIndex });
                console.log(firstIndex);
                productsListRef.current.scrollToOffset(
                  0,
                  firstIndex * 160,
                  true
                );
              } catch (e) {
                console.log(firstIndex, e);
              }
            }
          }}
        >
          <View style={{ padding: 5 }}>
            <Text style={{ fontSize: 32 }}>
              {i18n.t(item.name)}
              {index === categories.length - 1 ? "" : " |"}
            </Text>
          </View>
        </TouchableHighlight>
      )}
    />
  );

  return (
    <View style={styles.container}>
      {showDeliveryFields && (
        <View>
          <View
            style={{ flexDirection: "row", margin: 5, alignItems: "center" }}
          >
            <View
              style={{
                minWidth: 250,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text style={{ fontWeight: "bold" }}>{i18n.t('From:')} </Text>

              <Picker
                selectedValue={delivery && delivery.location}
                onValueChange={async (itemValue, itemIndex) => {
                  await setDeliveryDestination({
                    variables: {
                      token: token,
                      operator: operatorId,
                      delivery: delivery.id,
                      city: delivery.city,
                      street: delivery.street,
                      phone: delivery.phone,
                      comment: delivery.comment,
                      location: itemValue,
                    },
                  });
                }}
              >
                {locations &&
                  locations.map((l) => (
                    <Picker.Item
                      key={l.id}
                      label={`${l.name} - ${l.city}`}
                      value={l.id}
                    />
                  ))}
              </Picker>
            </View>
          </View>
          <View
            style={{ flexDirection: "row", margin: 5, alignItems: "center" }}
          >
            <View
              style={{
                minWidth: 250,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text style={{ fontWeight: "bold" }}>{i18n.t('City:')} </Text>
              <TextInput
                defaultValue={delivery && delivery.city}
	        multiline={true}
                onBlur={async (e) => {
                  await setDeliveryDestination({
                    variables: {
                      token: token,
                      operator: operatorId,
                      delivery: delivery.id,
                      city: e.nativeEvent.text,
                      street: delivery.street,
                      phone: delivery.phone,
                      comment: delivery.comment,
                      location: delivery.location,
                    },
                  });
                }}
                style={[
                  { borderBottomWidth: 1 },
                  styles.input,
                  colorScheme === "dark"
                    ? styles.darkThemeColors
                    : styles.lightThemeColors,
                ]}
              />
            </View>
<View
              style={{
                minWidth: 250,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text style={{ fontWeight: "bold" }}>{i18n.t('Phone:')} </Text>
              <TextInput
                defaultValue={delivery && delivery.phone}
	        multiline={true}
                onBlur={async (e) => {
                  await setDeliveryDestination({
                    variables: {
                      token: token,
                      operator: operatorId,
                      delivery: delivery.id,
                      city: delivery.city,
                      street: delivery.street,
                      phone: e.nativeEvent.text,
                      comment: delivery.comment,
                      location: delivery.location,
                    },
                  });
                }}
                style={[
                  { borderBottomWidth: 1 },
                  styles.input,
                  colorScheme === "dark"
                    ? styles.darkThemeColors
                    : styles.lightThemeColors,
                ]}
              />
            </View>

                     </View>
	  <View
style={{ flexDirection: "row", margin: 5, alignItems: "center" }}

	      >
 <View
              style={{
                minWidth: 500,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text style={{ fontWeight: "bold" }}>{i18n.t('Street:')} </Text>
              <TextInput
                defaultValue={delivery && delivery.street}
	        multiline={true}
                onBlur={async (e) => {
                  await setDeliveryDestination({
                    variables: {
                      token: token,
                      operator: operatorId,
                      delivery: delivery.id,
                      city: delivery.city,
                      street: e.nativeEvent.text,
                      phone: delivery.phone,
                      comment: delivery.comment,
                      location: delivery.location,
                    },
                  });
                }}
                style={[
                  { borderBottomWidth: 1, flex: 1, maxWidth: 500 },
                  styles.input,
                  colorScheme === "dark"
                    ? styles.darkThemeColors
                    : styles.lightThemeColors,
                ]}
              />
            </View>

	      </View>
          <View
            style={{ flexDirection: "row", flex: 1, margin: 5, alignItems: "center" }}
          >
                        <View
              style={{
		flex: 1,
                flexDirection: "row",
                alignItems: "stretch",
              }}
            >
              <Text style={{ fontWeight: "bold" }}>{i18n.t('Comment:')} </Text>
              <TextInput
                defaultValue={delivery && delivery.comment}
	        multiline={true}
                onBlur={async (e) => {
                  await setDeliveryDestination({
                    variables: {
                      token: token,
                      operator: operatorId,
                      delivery: delivery.id,
                      city: delivery.city,
                      street: delivery.street,
                      phone: delivery.phone,
                      comment: e.nativeEvent.text,
                      location: delivery.location,
                    },
                  });
                }}
                style={[
                  { borderBottomWidth: 1, flex: 1, maxWidth: 425 },
                  styles.input,
                  colorScheme === "dark"
                    ? styles.darkThemeColors
                    : styles.lightThemeColors,
                ]}
              />
            </View>
          </View>
        </View>
      )}
      <View style={styles.section1}>
        {delivery && delivery.orders && delivery.orders.length > 0 ? (
          <RecyclerListView
            style={{ flex: 1 }}
            ref={(ref) => {
              ordersRef.current = ref;
            }}
            contentContainerStyle={{ margin: 0 }}
            canChangeSize={true}
            dataProvider={dataProvider2}
            layoutProvider={layoutProvider2}
            renderAheadOffset={delivery.orders.length}
            rowRenderer={renderItem}
          />
        ) : null}
      </View>

      {(deliveryToken ||
        (operatorId &&
          delivery &&
          (operatorId === delivery.operator ||
            delivery.operator === null))) && (
        <View style={styles.section2}>
          <View style={[styles.newOrderSection2]}>
            {products && products.length > 0 ? (
              <StickyContainer
                stickyHeaderIndices={[0]}
                overrideRowRenderer={renderStickyItem}
              >
                <RecyclerListView
                  style={{ flex: 1, paddingTop: 55 }}
                  ref={(ref) => {
                    productsListRef.current = ref;
                  }}
                  contentContainerStyle={{ margin: 0 }}
                  canChangeSize={true}
                  extendedState={extendedState}
                  dataProvider={dataProvider}
                  layoutProvider={layoutProvider}
                  renderAheadOffset={products.length}
                  rowRenderer={renderProductItem}
                />
              </StickyContainer>
            ) : null}
          </View>
        </View>
      )}
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />

      <AlertRoot />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    alignItems: "stretch",
    justifyContent: "center",
  },
  containerListView: {
    flex: 1,
  },
  section1: {
    flex: 1,
  },
  lightThemeColors: {},
  darkThemeColors: {
    borderColor: "white",
    color: "white",
  },
  section2: {
    borderTopColor: "white",
    borderTopWidth: 5,
    borderBottomColor: "black",
    borderBottomWidth: 5,
    flex: 1,
  },
  newOrderSection1: {
    flex: 1,
  },
  newOrderSection2: {
    flex: 1,
  },
  button: {
    marginLeft: 50,
  },
  mainItem: {
    fontSize: 20,
  },
  buttonsContainer: {
    flexDirection: "row",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  logo: {
    width: 32,
    height: 32,
  },
  separator: {
    marginVertical: 2,
    height: 1,
    width: "80%",
  },
  backTextWhite: {
    color: "#FFF",
  },
  rowFrontContainer: {},
  rowFront: {
    alignItems: "stretch",
    backgroundColor: "#CCC",
    borderBottomColor: "black",
    borderBottomWidth: 1,
    justifyContent: "center",
    flex: 1,
  },
  rowBack: {
    alignItems: "center",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 15,
  },
  backRightBtn: {
    alignItems: "center",
    justifyContent: "center",
    width: 75,
    position: "absolute",
    top: 0,
    bottom: 0,
  },
});
