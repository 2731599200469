import { StatusBar } from "expo-status-bar";
import {
  Platform,
  TextInput,
  StyleSheet,
  FlatList,
  Image,
  useColorScheme,
  useWindowDimensions,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Text, View } from "../components/Themed";
import OrderItem from "../components/OrderItem";
import OrderItemGrouped from "../components/OrderItemGrouped";
import Alert, { AlertRoot } from "../components/Alert";
import ProductItem from "../components/ProductItem";

import { useQuery, useMutation, gql, useSubscription } from "@apollo/client";

const TABLES = gql`
  query getTableDetails($token: String!) {
    locations(token: $token) {
      id
      name
      city
      categories
      categoriesBlacklist
    }
    products(token: $token) {
      id
      title
      imageUrls
      prices
      category
      options {
        id
        title
        type
        required
        options {
          id
          title
          prices
        }
      }
      capabilities
      variants
    }
    tables(token: $token) {
      id
      operator
      available
      published
      capacity
      location
      number
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        variants
        comment
        options
        tableId
        timestamp
        capabilities
        status
      }
    }
  }
`;

const TABLES_SUBSCRIPTION = gql`
  subscription {
    tables {
      id
      operator
      available
      published
      capacity
      location
      number
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        comment
        options
        variants
        tableId
        timestamp
        capabilities
        status
      }
    }
  }
`;



const DELIVERIES = gql`
  query getDeliveryDetails($token: String!) {
    products(token: $token) {
      id
      title
      imageUrls
      category
      prices
      capabilities
      variants
    }
    deliveries(token: $token) {
      id
      operator
      location
      city
      street
      comment
      orders {
        id
        operator
        productIds
        quantities
        variants
        comment
        options
        timestamp
        capabilities
        status
      }
    }
  }
`;

const DELIVERIES_SUBSCRIPTION = gql`
  subscription {
    deliveries {
      id
      operator
      location
      city
      street
      comment
      orders {
        id
        operator
        productIds
        quantities
        comment
        options
        variants
        timestamp
        capabilities
        status
      }
    }
  }
`;

const COMPUTERS = gql`
  query getComputers($token: String!) {
    computers(token: $token) {
      id
      operator
      available
      published
      capacity
      type
      number
      name
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        comment
        options
        variants
        computerId
        timestamp
        capabilities
        status
      }
    }
  }
`;

const COMPUTERS_SUBSCRIPTION = gql`
  subscription {
    computers {
      id
      operator
      available
      published
      capacity
      number
      name
      type
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        comment
        options
        variants
        computerId
        capabilities
        timestamp
        status
      }
    }
  }
`;


const OPERATORS = gql`
  query getOperators($token: String!) {
    operators(token: $token) {
      id
      name
      timestampStart
      timestampEnd
      orders {
        id
        operator
        productIds
        options
        quantities
        comment
        variants
        timestamp
        capabilities
        status
      }
    }
  }
`;

const OPERATORS_SUBSCRIPTION = gql`
  subscription {
    operators {
      id
      name
      timestampStart
      timestampEnd
      orders {
        id
        operator
        productIds
        quantities
        variants
        options
        timestamp
        capabilities
        comment
        status
      }
    }
  }
`;

export default function OrdersScreen({ route }) {
  const [operatorId, setOperatorId] = useState("");
  const [token, setToken] = useState("");
  const [locationId, setLocationId] = useState("All");

  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const locationId = await AsyncStorage.getItem("location");if (locationId) {
          setLocationId(locationId);
        }
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const productIDCategoryMap = {

  }

  const { loading, error, data, refetch } = useQuery(TABLES, {
    variables: { token: token },
  });
  if (data && data.products) {

    for (let product of data.products) {
      productIDCategoryMap[product.id] = product.category
    }
  }

  const { tablesSubscriptionData, tablesSubscriptionLoading } =
    useSubscription(TABLES_SUBSCRIPTION);

    
  const { loading: deliveriesLoading, error: deliveriesError, data: deliveriesData, refetch: refetchDeliveries } = useQuery(DELIVERIES, {
    variables: { token: token },
  });

  const { deliveriesSubscriptionData, deliveriesSubscriptionLoading } =
    useSubscription(DELIVERIES_SUBSCRIPTION);

  const { loading: computersLoading, error: computersError, data: computersData, refetch: refetchComputers } = useQuery(COMPUTERS, {
    variables: { token: token },
  });

  const { computersSubscriptionData, computersSubscriptionLoading } =
    useSubscription(COMPUTERS_SUBSCRIPTION);

  const { loading: operatorsLoading, error: operatorsError, data: operatorsData, refetch: refetchOperators } = useQuery(OPERATORS, {
    variables: { token: token },
  });

  const { operatorsSubscriptionData, operatorsSubscriptionLoading } =
    useSubscription(OPERATORS_SUBSCRIPTION);

  const window = useWindowDimensions();

  if (!operatorId) return <Text>'Loading operator...'</Text>;

  if (loading || computersLoading || operatorsLoading) return <Text>Loading...</Text>;
  if (error || computersError || operatorsError) return <Text>Error :(</Text>;

  let numColumns = 1;
  if (window.width > 720) {
    numColumns = 2;
  }
  if (window.width > 1000) {
    numColumns = 3;
  }
  if (window.width > 1800) {
    numColumns = 4;
  }

  const renderItem = ({ item }) => (
    <OrderItem products={data.products} {...item} />
  );

  
  const renderItemGrouped = ({ item }) => (
    <OrderItemGrouped products={data.products} {...item}  />
  );

  const locationCategoriesWhitelist = locationId === 'All' ? [] : data.locations.filter(l => l.id === locationId)[0].categories

  let grouped = data.tables
      // .filter(t => locationId === "All" ? true : (locationId === t.location || t.orders))
	.flatMap((t) => ({ ...t, tableId: t.id, title: 'Table ' + t.number }))
      .filter((t) => t.orders.filter(o => o.status === "new").length > 0)
    .concat(computersData.computers
	    .flatMap((t) => ({ ...t, computerId: t.id, title: 'Computer ' + t.name }))
      .filter((t) => t.orders.filter(o => o.status === "new").length > 0))
    .concat(deliveriesData.deliveries
      .filter(t => locationId === "All" ? true : locationId === t.location)
	    .flatMap((t) => ({ ...t, deliveryId: t.id, title: 'Delivery ' + (t.street ? t.street : '') + (t.comment ? ' - ' + t.comment : '') }))
      .filter((t) => t.orders.filter(o => o.status === "new").length > 0))
    .concat(operatorsData.operators
      .flatMap((t) => ({ ...t, title: 'Operator ' + t.name }))
      .filter((t) => t.orders.filter(o => o.status === "new").length > 0))
    .sort(function (x, y) {
      return new Date(parseInt(x.timestamp)) - new Date(parseInt(y.timestamp));
    });

  const locationCategoriesBlacklist = locationId === 'All' ? [] : data.locations.filter(l => l.id === locationId)[0].categoriesBlacklist
  for (let item of grouped) {
    if (locationId === 'All') {
      continue;
    }

    if (item.location !== locationId) {
      item.orders = item.orders.filter(order => {
        const categoryId = productIDCategoryMap[order.productIds[0]];
        return (categoryId && locationCategoriesWhitelist && locationCategoriesWhitelist.includes(categoryId))
      })
    } else {

      item.orders = item.orders.filter(order => {
        const categoryId = productIDCategoryMap[order.productIds[0]];
        return !(categoryId && locationCategoriesBlacklist && locationCategoriesBlacklist.includes(categoryId))
      })
    }

  }
  grouped = grouped
      .filter((t) => t.orders.filter(o => o.status === "new").length > 0)

  const orders = 
    data.tables
      .filter(t => locationId === "All" ? true : locationId === t.location)
      .flatMap((t) => t.orders.map(o => ({ ...o, title: t.number })))
      .filter((o) => o.status === "new")
    .concat(computersData.computers
      .flatMap((t) => t.orders.map(o => ({ ...o, title: t.name })))
      .filter((o) => o.status === "new"))
    .concat(deliveriesData.deliveries
      .filter(t => locationId === "All" ? true : locationId === t.location)
      .flatMap((t) => t.orders.map(o => ({ ...o, deliveryId: t.id, title: t.street })))
      .filter((o) => o.status === "new"))
    .concat(operatorsData.operators
      .flatMap((t) => t.orders.map(o => ({ ...o, title: t.name })))
      .filter((o) => o.status === "new"))
    .sort(function (x, y) {
      return new Date(parseInt(x.timestamp)) - new Date(parseInt(y.timestamp));
    });
  
  return (
    <View style={styles.container}>
      <View style={styles.section1}>
        <FlatList
          key={numColumns}
          onRefresh={() => refetch()}
          refreshing={loading}
          data={grouped}
          style={{flex: 1}}
          renderItem={renderItemGrouped}
          keyExtractor={(item) => item.id}
          numColumns={numColumns}
        />
      </View>

      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  section1: {
    flex: 1,
  },
  mainItem: {
    fontSize: 20,
    fontFamily: "Pricedown-Bl",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
});
