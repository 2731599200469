import { AntDesign } from "@expo/vector-icons";
import React, { useState, useEffect } from "react";
import { useColorScheme } from "react-native";
import { useQuery, gql, useMutation, useSubscription } from "@apollo/client";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { TouchableHighlight, Text } from "../components/Themed";
import TableOrderView from "../components/TableOrderView";

const TABLE_DETAILS = gql`
  query getTableDetails($tableId: ID!, $token: String!) {
    products(token: $token) {
      id
      title
      subtitle
      imageUrls
      prices
      capabilities
      category
      variants
      volumes
      options {
        id
        title
        type
        required
        options {
          id
          title
          prices
        }
      }
    }
    categories(token: "token") {
      id
      name
      order
    }
    tables(token: $token) {
      id
      operator
      available
      published
      capacity
      location
      finalized
      number
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        variants
        options
        comment
        capabilities
        tableId
        timestamp
        status
      }
    }
    table(token: $token, id: $tableId) {
      id
      operator
      available
      published
      capacity
      location
      finalized
      number
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        variants
        options
        comment
        capabilities
        tableId
        timestamp
        status
      }
    }
  }
`;

const TABLES_SUBSCRIPTION = gql`
  subscription {
    tables {
      id
      operator
      available
      published
      capacity
      location
      finalized
      number
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        variants
        comment
        options
        capabilities
        tableId
        timestamp
        status
      }
    }
  }
`;

export default function TableDetailsScreen(props) {
  const tableId = props.route.params && props.route.params.tableId;
  const tableToken = props.route.params && props.route.params.tableToken;
  const colorScheme = useColorScheme();

  const [operatorId, setOperatorId] = useState("");
  const [token, setToken] = useState("");
  const [locationId, setLocationId] = useState("All");

  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const locationId = await AsyncStorage.getItem("location");
        if (locationId) {
          setLocationId(locationId);
        }
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const { loading, error, data } = useQuery(TABLE_DETAILS, {
    variables: { tableId, token: token || tableToken || ''},
    nextFetchPolicy: 'cache-only',
  });

  

  const { data: subscriptionData, loading: subscriptionLoading, error: subscriptionError } =
    useSubscription(TABLES_SUBSCRIPTION);

  
  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error :(</Text>;

  const table = subscriptionData ? subscriptionData.tables.filter(t => t.id === tableId)[0] : data.table;

  return <TableOrderView {...props} categories={data.categories} products={data.products} table={table} tables={locationId === 'All' ? data.tables : data.tables.filter(t => t.location === locationId)} />;
}
