
import Svg, { Circle, G, Rect, Text } from "react-native-svg";
import {Platform, useWindowDimensions} from 'react-native';
import { processFontFamily } from "expo-font";

export default function TablePicker({tables, operatorId, onClick, onClose, excludeTableId}) {

    
    const window = useWindowDimensions();

    const svgCloseProps = {};
    const touchClosePropName = Platform.OS === 'web' ? 'onClick' : 'onPress';
    svgCloseProps[touchClosePropName] = () => {
      if (onClose) {
        onClose();
      }
      return true;
    };

    return (
        <Svg
          height={`${window.height}`}
          width={`${window.width}`}
        >
          <Rect x="0" width={window.width/2} y="0" height={window.height/2} fill="rgb(60, 60, 60)" {...svgCloseProps} />
          <Rect x={window.width/2} width={window.width/2} y="0" height={window.height/2} fill="rgb(80, 80, 80)" {...svgCloseProps} />
          <Rect x="0" width={window.width/2} y={window.height/2} height={window.height/2} fill="rgb(70, 70, 70)" {...svgCloseProps} />
          <Rect x={window.width/2} width={window.width/2} y={window.height/2} height={window.height/2} fill="rgb(50, 50, 50)" {...svgCloseProps} />
          {tables.map((table) => {

            if (excludeTableId === table.id) {
                return;
            }
            
            let strokeColor = "black";
            let fillColor = "#a9a9a9";
  
            if (operatorId === table.operator) {
              strokeColor = "#ff008d";
              fillColor = "blue";
  
              if (table.orders.filter((o) => o.status === "new").length > 0) {
                fillColor = "#8b0000";
              }
            }
  
            if (operatorId !== table.operator && table.operator != null) {
              strokeColor = "blue";
              fillColor = "white";
            }
  
            if (table.orders.filter((o) => o.status === "new").length > 0) {
              strokeColor = "#ff008d";
            }

            if (table.orders.length > 0 && table.orders.filter((o) => o.status === "delivered").length === table.orders.length) {
              strokeColor = "#00ff9f";
            }
  
            const svgProps = {};
            const touchPropName = Platform.OS === 'web' ? 'onClick' : 'onPress';
            svgProps[touchPropName] = () => {
              onClick(table);
              return true;
            };
  
            return (
              <G key={table.id}>
                <Circle
                  cx={table.x * window.width}
                  cy={table.y * window.height}
                  r="30"
                  stroke={strokeColor}
                  strokeWidth="4.0"
                  fill={fillColor}
                  {...svgProps}
                />
                <Text
                  x={table.number > 9 ? table.x * window.width - 16 : table.x * window.width - 8}
                  y={table.y * window.height + 10}
                  fill="white"
                  stroke="black"
                  strokeWidth="2"
                  fontSize="2em"
                  fontFamily={processFontFamily("Pricedown-Bl")}
                  {...svgProps}
                >
                  {table.number}
                </Text>
              </G>
            );
          })}
        </Svg>)
}