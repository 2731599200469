
import React, { useState, useEffect, useRef, useMemo } from "react";
import { StatusBar } from "expo-status-bar";
import {
  Animated,
  Platform,
  Modal,
  StyleSheet,
  FlatList,
  TouchableHighlight,
  useColorScheme,
  useWindowDimensions,
} from "react-native";
import StickyContainer from 'recyclerlistview/dist/reactnative/core/StickyContainer';
import AsyncStorage from "@react-native-async-storage/async-storage";
import "react-native-get-random-values";
import { useLinkTo } from "@react-navigation/native";
import Swipeable from 'react-native-gesture-handler/Swipeable';
import { RectButton } from 'react-native-gesture-handler';
import { MaterialIcons, MaterialCommunityIcons, Feather  } from "@expo/vector-icons";
import { useMutation, gql, useSubscription } from "@apollo/client";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import { Text, View } from "./Themed";
import Alert, { AlertRoot } from "./Alert";
import ProductItem from "./ProductItem";
import i18n from "../utils/localization";
import SwipeableProductItem from './SwipeableProductItem';
import { RecyclerListView, DataProvider, LayoutProvider } from "recyclerlistview";
import { useActionsHeader } from "./ActionsHeader";
import TablePicker from "./TablePicker";
const FINALIZE_TABLE = gql`
  mutation finalizeTable(
    $token: String!
    $operator: ID!
    $table: ID!
    $tableToken: String!
    $paymentType: String!
  ) {
    finalizeTable(
      token: $token
      operator: $operator
      table: $table
      tableToken: $tableToken
      paymentType: $paymentType
    )
  }
`;

const TABLE_RECEIPT = gql`
  mutation tableReceipt(
    $token: String!
    $operator: ID!
    $table: ID!
    $tableToken: String!
  ) {
    tableReceipt(
      token: $token
      operator: $operator
      table: $table
      tableToken: $tableToken
    )
  }
`;

const ARCHIVE_TABLE = gql`
  mutation archiveTable(
    $token: String!
    $operator: ID!
    $table: ID!
    $tableToken: String!
  ) {
    archiveTable(
      token: $token
      operator: $operator
      table: $table
      tableToken: $tableToken
    )
  }
`;

const SWAP_TABLES = gql`
  mutation swapTables(
    $token: String!
    $operator: ID!
    $table: ID!
    $otherTable: ID!
  ) {
    swapTables(
      token: $token
      operator: $operator
      table: $table
      otherTable: $otherTable
    )
  }
`;


const CHANGE_ORDER_STATUS = gql`
  mutation changeOrderStatus(
    $token: String!
    $operator: ID!
    $table: ID!
    $id: ID!
    $status: String!
  ) {
    changeOrderStatus(
      token: $token
      operator: $operator
      table: $table
      id: $id
      status: $status
    ) {
      id
      operator
      productIds
      quantities
      variants
      comment
      tableId
      capabilities
      timestamp
      status
    }
  }
`;


const getItemLayout = (data, index) => ({ length: 100, offset: (100 + 0) * index, index})
const TAKE_TABLE_OWNERSHIP = gql`
  mutation takeTableOwnership(
    $token: String!
    $operator: ID!
    $table: ID!
  ) {
    takeTableOwnership(
      token: $token
      operator: $operator
      table: $table
    )
  }
`;

const CREATE_ORDER = gql`
  mutation createOrder(
    $id: ID!
    $token: String!
    $operator: ID!
    $table: ID!
    $productIds: [ID!]!
    $quantities: [Int!]!
    $variants: [String!]!
    $capabilities: [String]!
    $comment: String
    $options: [String]!
  ) {
    createOrder(
      id: $id
      token: $token
      operator: $operator
      table: $table
      productIds: $productIds
      quantities: $quantities
      variants: $variants
      comment: $comment
      capabilities: $capabilities
      options: $options
    ) {
      id
      operator
      available
      published
      capacity
      finalized
      number
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        variants
        comment
        options
        capabilities
        tableId
        timestamp
        status
      }
    }
  }
`;


const GENERATE_TABLE_TOKEN = gql`
  mutation generateTableToken($token: String!, $operator: ID!, $table: ID!) {
    generateTableToken(token: $token, operator: $operator, table: $table) {
      id
      operator
      available
      published
      capacity
      finalized
      number
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        variants
        comment
        tableId
        capabilities
        timestamp
        status
      }
    }
  }
`;


const DELETE_ORDER = gql`
  mutation deleteOrder(
    $id: ID!
    $token: String!
    $operator: ID!
    $table: ID!
  ) {
    deleteOrder(
      id: $id
      token: $token
      operator: $operator
      table: $table
    ) {
      id
      operator
      available
      published
      finalized
      capacity
      number
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        variants
        comment
        capabilities
        tableId
        timestamp
        status
      }
    }
  }
`;

const CLEAR_TABLE_ORDERS = gql`
  mutation clearTableOrders($token: String!, $operator: ID!, $table: ID!) {
    clearTableOrders(token: $token, operator: $operator, table: $table) {
      id
      operator
      available
      published
      capacity
      number
      finalized
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        variants
        comment
        tableId
        capabilities
        timestamp
        status
      }
    }
  }
`;

const TABLES_SUBSCRIPTION = gql`
  subscription {
    tables {
      id
      operator
      available
      published
      capacity
      finalized
      number
      token
      x
      y
      orders {
        id
        operator
        productIds
        quantities
        comment
        tableId
        capabilities
        timestamp
        status
      }
    }
  }
`;

const Jared = { width: 1920, numColumns: 1 };

export default function TableOrderView({ route, navigation, products, table, categories, tables }) {
  const tableId = route.params && route.params.tableId;
  const tableToken = route.params && route.params.tableToken;

  const colorScheme = useColorScheme();

  const [swipeAction, setSwipeAction] = useState("Deliver");
  const [operatorId, setOperatorId] = useState(null);
  const [token, setToken] = useState(null);
  const [role, setRole] = useState(null);
  

  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const storedRole = await AsyncStorage.getItem("role");
        setRole(storedRole);
      } catch (ex) {}

      
      try {
        const swipeAction = await AsyncStorage.getItem("orderItemSwipe");
        setSwipeAction(swipeAction || 'Deliver')
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const { data: tablesSubscriptionData, loading: tablesSubscriptionLoading } =
    useSubscription(TABLES_SUBSCRIPTION);

  const [
    finalizeTable,
    { finalizeTableData, finalizeTableLoading, finalizeTableError },
  ] = useMutation(FINALIZE_TABLE);

  const [
    tableReceipt,
    { tableReceiptData, tableReceiptLoading, tableReceiptError },
  ] = useMutation(TABLE_RECEIPT);

  const [
    archiveTable,
    { archiveTableData, archiveTableLoading, archiveTableError },
  ] = useMutation(ARCHIVE_TABLE);

  const [
    swapTables,
  ] = useMutation(SWAP_TABLES);

  const [
    takeTableOwnership
  ] = useMutation(TAKE_TABLE_OWNERSHIP);

  const [
    createTableOrder,
    { createTableOrderData, createTableOrderLoading, createTableOrderError },
  ] = useMutation(CREATE_ORDER, {
    ignoreResults: true,
  });

  const [
    deleteTableOrder,
    { deleteTableOrderData, deleteTableOrderLoading, deleteTableOrderError },
  ] = useMutation(DELETE_ORDER, {
    ignoreResults: true
  });


  const [
    changeOrdersStatus,
    {
      changeOrdersStatusData,
      changeOrdersStatusLoading,
      changeOrdersStatusError,
    },
  ] = useMutation(CHANGE_ORDER_STATUS, {
    ignoreResults: true
  });

  
  const [
    generateTableToken,
    {
      generateTableTokenData,
      generateTableTokenLoading,
      generateTableTokenError,
    },
  ] = useMutation(GENERATE_TABLE_TOKEN);


  const [
    clearTableOrders,
    { clearTableOrdersData, clearTableOrdersLoading, clearTableOrdersError },
  ] = useMutation(CLEAR_TABLE_ORDERS);

  const productsListRef = useRef(null);
  const ordersRef = useRef(null);

  const linkTo = useLinkTo();

  const window = useWindowDimensions();
  const [tablePickerVisible, setTablePickerVisible] = useState(false);

  Jared.width = window.width;
  const [dataProvider, setDataProvider] = useState(() => { return new DataProvider((r1, r2) => r1 !== r2) });
  const [dataProvider2, setDataProvider2] = useState(() => { return new DataProvider((r1, r2) => r1 !== r2) });
  const [extendedState, setExtendedState] = useState({
    chooseVariantValue: {},
      displayMode: {},
    commentValue: {},
    imageValue: {},
    optionsValue: {},
  });
  const [numColumns, setNumColumns] = useState(() => {
    let numColumns = 1;
    if (window.width > 720) {
      numColumns = 2;
    }
    if (window.width > 1000) {
      numColumns = 3;
    }
    if (window.width > 1800) {
      numColumns = 4;
    }
    return numColumns;
  });

  const [layoutProvider, setLayoutProvider] = useState(() => { return new LayoutProvider(
    () => {
      return 'VSEL';
    },
    (type, dim) => { 
      switch (type) {
        case 'VSEL':
          dim.width = ( Jared.width - 17) / Jared.numColumns;
          dim.height = 160;
          break;
        default:
          dim.width = 0;
          dim.heigh = 0;
      }
    }
  );})

  const [layoutProvider2, setLayoutProvider2] = useState(() => { return new LayoutProvider(
    () => {
      return 'VSEL';
    },
    (type, dim) => { 
      switch (type) {
        case 'VSEL':
          dim.width = ( Jared.width - 17) / Jared.numColumns;
          dim.height = 160;
          break;
        default:
          dim.width = 0;
          dim.heigh = 0;
      }
    }
  );})

  useEffect(() => {
    let numColumns = 1;
    if (window.width > 720) {
      numColumns = 2;
    }
    if (window.width > 1000) {
      numColumns = 3;
    }
    if (window.width > 1800) {
      numColumns = 4;
    }
    setNumColumns(numColumns);
    Jared.width = window.width;
    Jared.numColumns = numColumns;
  }, [window]);

  useEffect(() => {
    if (products) {
      setDataProvider(dataProvider.cloneWithRows(products))
    }
  }, [products]);

  useEffect(() => {
    if (table && table.orders) {
      setDataProvider2(dataProvider2.cloneWithRows(table.orders))
    }
  }, [table]);

  

  const total = table ? table.orders
    .map((order, i) => {
      return order.productIds
        .map((pis, pisindex) => {
          const product = products.filter((p) => p.id === pis)[0];
          const indexOfVariant = product.variants.findIndex(
            (v) => v === order.variants[pisindex]
          );
          
          const optionsCost = order.options ? order.options.reduce((acc, v, i) => {
            if (i % 2 == 0) {
              const poption = product.options.filter(o => o.id === v)[0];
              const pchoice = poption.options.filter(o => o.id === order.options[i+1])[0]
              return acc + pchoice.prices[indexOfVariant];
            } else {
              return acc + 0.0;
            }
          }, 0.0) : 0.0;

          return (product.prices[indexOfVariant] * order.quantities[pisindex]) + optionsCost;
        })
        .reduce((a, b) => a + b, 0);
    })
    .reduce((a, b) => a + b, 0) : [];

  useActionsHeader(navigation, table, token, operatorId, tableId, [
    {
      condition: !table.finalized && role === 'admin' && (operatorId === table.operator || table.operator === null) && table.orders.length > 0 && !tableToken,
      confirm: 'Are you sure you want to delete ALL orders?',
      onPress: () => {
        clearTableOrders({
          variables: {
            token: token,
            operator: operatorId,
            table: table.id,
          },
        })
      },
      icon: <AntDesign name="delete" size={24} color={colorScheme === "dark" ? "white" : "black"} />
    },
    {
      condition: !table.finalized && (operatorId === table.operator || table.operator === null) && table.orders.length > 0 && !tableToken,
      confirm: 'Are you sure you want to finalize this table?',
      onPress: () => {
        finalizeTable({
          variables: {
            token: token,
            operator: operatorId,
            table: table.id,
            tableToken: "",
            paymentType: "cash",
          },
        })
      },
      icon: <MaterialIcons name="attach-money" size={24} color={colorScheme === "dark" ? "white" : "black"} />
    },
    {
      condition: !table.finalized && (operatorId === table.operator || table.operator === null) && table.orders.length > 0 && !tableToken,
      confirm: 'Are you sure you want to finalize this table?',
      onPress: () => {
        finalizeTable({
          variables: {
            token: token,
            operator: operatorId,
            table: table.id,
            tableToken: "",
            paymentType: "card",
          },
        })
      },
      icon: <MaterialIcons name="credit-card" size={24} color={colorScheme === "dark" ? "white" : "black"} />
    },
    {
      condition: table.finalized && (operatorId === table.operator || table.operator === null) && table.orders.length > 0 && !tableToken,
      onPress: () => {
        tableReceipt({
          variables: {
            token: token,
            operator: operatorId,
            table: table.id,
            tableToken: "",
          },
        })
      },
      icon: <Ionicons name="receipt-outline" size={24} color={colorScheme === "dark" ? "white" : "black"} />
    },
    {
      condition: table.finalized && (operatorId === table.operator || table.operator === null) && table.orders.length > 0 && !tableToken,
      confirm: 'Are you sure you want to archive this table?',
      onPress: () => {
        archiveTable({
          variables: {
            token: token,
            operator: operatorId,
            table: table.id,
            tableToken: "",
          },
        })
      },
      icon: <MaterialCommunityIcons name="folder-key-outline" size={24} color={colorScheme === "dark" ? "white" : "black"} />
    },
    {
      condition: true,
      icon: <View style={{ backgroundColor: "transparent", flexDirection: 'row' }}>
                  <Text style={[{fontSize: 14}]}>
                    {total.toFixed(2)}
                  </Text>
            </View>
    },
    {
      condition: operatorId !== table.operator && table.operator !== null && !tableToken,
      confirm: 'Are you sure you want to take ownership?',
      onPress: () => {
        takeTableOwnership({
          variables: {
            token: token,
            operator: operatorId,
            table: table.id
          }
        })
      },
      icon: <Feather name="refresh-ccw" size={24} color={colorScheme === "dark" ? "white" : "black"} />
    },
    {
      condition: operatorId === table.operator && table.operator !== null && !tableToken,
      onPress: () => {
        setTablePickerVisible(true);
      },
      icon: <MaterialIcons name="swap-vert-circle" size={24} color={colorScheme === "dark" ? "white" : "black"} />
    },
    {
      condition: (!tableToken && (table.operator == null || operatorId === table.operator)) || tableToken,
      onPress: async () => {
        if (!table.token && operatorId) {
          const response = await generateTableToken({
            variables: {
              token: token,
              operator: operatorId,
              table: tableId,
            },
          });
          linkTo(
            `/qr/tables/${tableId}/token/${response.data.generateTableToken.token}`
          );
        } else if (tableToken || operatorId) {
          linkTo(`/qr/tables/${tableId}/token/${table.token}`);
        }
      },
      icon: <MaterialIcons name="ios-share" size={24} color={colorScheme === "dark" ? "white" : "black"} />
    }
  ]);

  if (finalizeTableLoading) return "Submitting...";
  if (finalizeTableError) return `Submission error! ${error.message}`;

  if (clearTableOrdersLoading) return "Submitting...";
  if (clearTableOrdersError) return `Submission error! ${error.message}`;

  if (!tableId) {
    return null;
  }

  const renderProductItem = (type, item, index, extendedState) => <SwipeableProductItem key={item.id} products={products} item={item} onCreate={(orderData) => {
      createTableOrder({
        variables: {
          ...orderData,
          token: token || tableToken,
          operator: operatorId || tableToken,
          table: table.id,
        },
      });
      setTimeout(() => {
        ordersRef.current.scrollToOffset(0, table.orders.length * 160, true, true);
      }, 900);
    }}
    openOnTap={true}
    colorScheme={colorScheme}
    index={index}
    chooseVariantValue={extendedState.chooseVariantValue[index]}
    chooseVariant={(index, variant) => {
      const newState = {
        ...extendedState,
        chooseVariantValue: {
          ...extendedState.chooseVariantValue,
        }
      };
      newState.chooseVariantValue[index] = variant;
      setExtendedState(newState);
    }}
    displayMode={extendedState.displayMode[index]}				
    setDisplayMode={(index, displayMode) => {
      const newState = {
          ...extendedState,
	  displayMode: {
	      ...extendedState.displayMode,
	  }
      };
      newState.displayMode[index] = displayMode;						         setExtendedState(newState);
    }}
    imageValue={extendedState.imageValue[index]}
    setImageValue={(index, image) => {
      const newState = {
        ...extendedState,
        imageValue: {
          ...extendedState.imageValue,
        }
      };
      newState.imageValue[index] = image;
      setExtendedState(newState);
    }}
    optionsValue={extendedState.optionsValue[index]}
    setOptionsValue={(index, option, value) => {
      const newState = {
        ...extendedState,
        optionsValue: {
          ...extendedState.optionsValue
        }
      }
      const existing = newState.optionsValue[index] || {};
      existing[option] = value
      if (!value) {
        delete existing[option]
      }
      newState.optionsValue[index] = existing;
      setExtendedState(newState);
    }}
    commentValue={extendedState.commentValue[index]}
    setCommentValue={(index, comment) => {
      const newState = {
        ...extendedState,
        commentValue: {
          ...extendedState.commentValue,
        }
      };
      newState.commentValue[index] = comment;
      setExtendedState(newState);
    }}
  />

  const renderItem = (type, item, index, extendedState) => {
    let swipeableRow;

    function updateRef (ref) {
      swipeableRow = ref;
    };

    return item.productIds.map((productId, index) => {
      const product = products.filter((p) => p.id === productId)[0];
      const indexOfVariant = product.variants.findIndex(
        (v) => v === item.variants[0]
      );
      const price = product.prices[indexOfVariant];
      return (
        <Swipeable
        ref={updateRef}
        useNativeAnimations={Platform.OS !== 'web'}
        enableTrackpadTwoFingerGesture
        onSwipeableOpen={(direction) => {
          if (tableToken) {
            if (swipeableRow) {
              swipeableRow.close();
            }
            return;
          }
          if (direction === 'right') {
            const index = products.findIndex((product) => product.id === item.id);
            const product = products[index];

            setTimeout(() => {
              if (swipeAction === 'Delete') {

              deleteTableOrder({
                variables: {
                  token: token,
                  operator: operatorId,
                  table: table.id,
                  id: item.id,
                },
              });
              } else if (swipeAction === 'Deliver') {

                  changeOrdersStatus({
                    variables: {
                      id: item.id,
                      token: token,
                      operator: operatorId,
                      table: tableId,
                      status: "delivered",
                    },
                  });
              }
            }, 30)
            
            console.log('try swipe')
            if (swipeableRow) {
              swipeableRow.close();
            }
          }
        }}
        onSwipeableClose={(direction) => {
          console.log(`Closing swipeable to the ${direction}`);
        }}
        renderRightActions={(progress, dragX) => {
        const trans = dragX.interpolate({
          inputRange: [0, 50, 100, 101],
          outputRange: [-20, 0, 0, 1],
        });
        return (
          <RectButton style={{
            alignItems: 'center',
            flex: 1,
            justifyContent: 'center',
          } } onPress={() => {}}>
            <Animated.Text
              style={[
                {
                  color: 'white',
                  //transform: [{ translateX: trans }],
                },
              ]}>
              {!tableToken && swipeAction === 'Delete' && 'BYE, Salut, Arrivederci, Чао, 😔'}
              {!tableToken && swipeAction === 'Deliver' && 'Good job! Bravo! ✅👌⚡🚀'}
            </Animated.Text>
          </RectButton>
        );
      }}>
            <View style={{ flex: 1 }}>
              <ProductItem
                {...product}
                variant={indexOfVariant}
                selectedOptions={item.options}
                itemStyle={{padding: 15}}
                comment={item.comment}
                colorScheme={colorScheme}
                status={item.status}
                onRemove={(options) => {
                }}
                index={index}
              />
            </View>
        {/* </TouchableHighlight> */}
      </Swipeable>
      );
    })[0];
  };


  const renderStickyItem = (type, item, index) => (
    <FlatList
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  style={{backgroundColor: colorScheme === 'light' ? 'white' : 'black'}}
                  data={categories}
                  keyExtractor={(item, index) => item.id}
                  renderItem={({ item, index }) => (
                    <TouchableHighlight onPress={() => {
                      if (productsListRef.current) {
                        let firstIndex = 0;
                        try {
                          firstIndex = Math.floor(products.findIndex(p => p.category === item.id) / numColumns);
                          //productsListRef.current.scrollToIndex({ viewOffset: 65, animated: true, index: firstIndex });
                          console.log(firstIndex);
                          productsListRef.current.scrollToOffset(0, firstIndex * 160, true);
                        } catch (e) {
                          console.log(firstIndex, e);
                        }
                      }
                    }}>
                      <View style={{ padding: 5 }}>
                        <Text style={{ fontSize: 32 }}>{i18n.t(item.name)}{index === categories.length - 1 ? '' : ' |'}</Text>
                      </View>
                    </TouchableHighlight>
                  )}
                />
  );

  return (
    <View style={styles.container}>
      <View style={styles.section1}>
        { table && table.orders && table.orders.length > 0 ? <RecyclerListView
              style={{ flex: 1, minWidth: 1, minHeight: 1 }}
              ref={(ref) => { ordersRef.current = ref}}
              contentContainerStyle={{ margin: 3 }}
              canChangeSize={true}
              dataProvider={dataProvider2}
              layoutProvider={layoutProvider2}
              renderAheadOffset={table.orders.length}
              rowRenderer={renderItem}
          />
        : null}
      </View>

      {(tableToken ||
        (operatorId &&
          (operatorId === table.operator ||
            table.operator === null))) && (
        <View style={styles.section2}>
          <View style={[styles.newOrderSection2]}>
            {products && products.length > 0 ? <StickyContainer
                stickyHeaderIndices={[0]}
		style={{flex: 1}}
                overrideRowRenderer={renderStickyItem}
              >
              <RecyclerListView
                style={{ flex: 1, paddingTop: 55, minWidth: 1, minHeight: 1 }}
                ref={(ref) => { productsListRef.current = ref}}
                contentContainerStyle={{ margin: 3 }}
                canChangeSize={true}
                extendedState={extendedState}
                dataProvider={dataProvider}
                layoutProvider={layoutProvider}
                renderAheadOffset={products.length}
                rowRenderer={renderProductItem}
              />
            </StickyContainer>
            : null}
          </View>
        </View>
      )}
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
      
      <AlertRoot />
      <Modal
        animationType="slide"
        transparent={true}
        visible={tablePickerVisible}
        onRequestClose={() => {
          setTablePickerVisible(false);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>Which table to swap with?</Text>
            <TablePicker tables={tables} operatorId={operatorId} excludeTableId={table.id} onClick={(otherTable) => {
              setTablePickerVisible(false);
              Alert.alert(
                "Please confirm",
                `Swap ${table.number} with ${otherTable.number} ?`,
                [
                    {
                    text: "Cancel",
                    onPress: () => { }
                    },
                    {
                    text: "Yes",
                    style: "cancel",
                    onPress: () => {
                      swapTables({
                        variables: {
                          token: token,
                          operator: operatorId,
                          table: table.id,
                          otherTable: otherTable.id
                        },
                      })
                    }
                        
                    },
                ],
                { cancelable: false },
                )
            }}
            onClose={() => {setTablePickerVisible(false)}}
            />
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    alignItems: "stretch",
    justifyContent: "center",
  },
  containerListView: {
    flex: 1,
  },
  section1: {
    flex: 1,
  },
  section2: {
    borderTopColor: "white",
    borderTopWidth: 5,
    borderBottomColor: "black",
    borderBottomWidth: 5,
    flex: 1,
  },
  newOrderSection1: {
    flex: 1,
  },
  newOrderSection2: {
    flex: 1,
  },
  button: {
    marginLeft: 50,
  },
  mainItem: {
    fontSize: 20,
  },
  buttonsContainer: {
    flexDirection: "row",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  logo: {
    width: 32,
    height: 32,
  },
  separator: {
    marginVertical: 2,
    height: 1,
    width: "80%",
  },
  backTextWhite: {
    color: "#FFF",
  },
  rowFrontContainer: {},
  rowFront: {
    alignItems: "stretch",
    backgroundColor: "#CCC",
    borderBottomColor: "black",
    borderBottomWidth: 1,
    justifyContent: "center",
    flex: 1,
  },
  rowBack: {
    alignItems: "center",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 15,
  },
  backRightBtn: {
    alignItems: "center",
    justifyContent: "center",
    width: 75,
    position: "absolute",
    top: 0,
    bottom: 0,
  },
});
