import React from 'react';
import { StatusBar } from "expo-status-bar";
import { useWindowDimensions, Pressable, Platform, StyleSheet, FlatList, Text, useColorScheme } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect, useState } from "react";
import { View } from "../components/Themed";
import { useQuery, gql } from "@apollo/client";
import { DatePickerModal } from 'react-native-paper-dates';
import Config from "../constants/Config";

const REPORT_QUERY = gql`query getReport($adminToken: String!, $dateFrom: String, $dateTo: String){
  report(token: $adminToken, dateFrom: $dateFrom, dateTo: $dateTo){
    header
    periods {
      date
      total
      values
    }
}}`;

function formatDate(date) {
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // Months start at 0!
  let dd = date.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  return dd + '/' + mm + '/' + yyyy;
}


export default function OperatorSettingsReportScreen() {
  const [operatorId, setOperatorId] = useState("");
  const [token, setToken] = useState("");
  const [adminToken, setAdminToken] = useState("");
  const window = useWindowDimensions();
  const colorScheme = useColorScheme();
  const [dateString] = useState((new Date()).toISOString());

  const [range, setRange] = React.useState({ startDate: undefined, endDate: undefined });

  const [open, setOpen] = React.useState(false);

   const onDismiss = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirm = React.useCallback(
      ({ startDate, endDate }) => {
        setOpen(false);
        setRange({ startDate, endDate });
      },
      [setOpen, setRange]
    );

  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}

      try {
        const storedAdminToken = await AsyncStorage.getItem("adminToken");
        setAdminToken(storedAdminToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const { loading, error, data } = useQuery(REPORT_QUERY, {
    variables: { adminToken, dateFrom: range.startDate, dateTo: range.endDate },
  });
  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error :(</Text>;
  
  return (
    <View style={styles.container}>
        <View style={{flex: 1}}>
          <Pressable onPress={() => setOpen(true)}>
            <View style={{backgroundColor: Config.companyColor, padding: 15, borderRadius: 12, marginTop: 4 }}><Text style={{color: 'white'}}>Pick range</Text></View>
          </Pressable>
          <DatePickerModal
            locale="en"
            mode="range"
            visible={open}
            onDismiss={onDismiss}
            startDate={range.startDate}
            endDate={range.endDate}
            onConfirm={onConfirm}
          />
          {/* <Text style={{color: colorScheme === 'dark' ? 'white' : 'black', fontFamily: 'Pricedown-BL', fontSize: 80}}>Total {data.report.total}</Text> */}
          <FlatList 
            data={data.report.periods}
            keyExtractor={(item, index) => index}
            renderItem={({item, periodIndex}) => <View style={{padding: 15}}>
              <Text style={{color: colorScheme === 'dark' ? 'white' : 'black', fontFamily: 'Pricedown-BL', fontSize: 32, borderBottomColor: Config.companyColor, borderBottomWidth: 4}}>{formatDate(range.startDate)} - {formatDate(range.endDate)} - {item.total.toFixed(2)}</Text>
              {data.report.header.map((headerItem, index) => {
                
                return (
                <View key={headerItem+periodIndex}>
                  {index > 1 && index < 11 && <Text style={{color: colorScheme === 'dark' ? 'white' : 'black', fontFamily: 'Pricedown-BL', fontSize: 18}}>{headerItem} - {item.values[index]}</Text>}
                  {index > 1 && index > 10 && index % 2 === 1 && <Text style={{color: colorScheme === 'dark' ? 'white' : 'black', fontFamily: 'Pricedown-BL', fontSize: 18}}>{headerItem} - {item.values[index]} | {item.values[index+1]} </Text>}
                </View>
              )
              })}
            </View>}
          />
        </View>
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
  lightThemeColors: {},
  darkThemeColors: {
    borderColor: "white",
    color: "white",
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
