import { StatusBar } from "expo-status-bar";
import {
  Pressable,
  Platform,
  StyleSheet,
  ScrollView,
  Text,
  TextInput,
  useColorScheme,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect, useState } from "react";
import { useLinkTo } from "@react-navigation/native";
import { View } from "../components/Themed";
import { useQuery, gql, useMutation } from "@apollo/client";
import Config from "../constants/Config";

import { Picker } from "@react-native-picker/picker";

const LOCATIONS_QUERY = gql`
  query getLocations($token: String!) {
    locations(token: $token) {
      id
      name
      city
    }
  }
`;

const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      adminToken
      apiToken
      role
    }
  }
`;

export default function OperatorSettingsScreen() {
  const [operatorId, setOperatorId] = useState("");
  const [locationId, setLocationId] = useState("All");
  const [swipeAction, setSwipeAction] = useState("Deliver");
  const [role, setRole] = useState("");
  const [token, setToken] = useState("");
  const [adminToken, setAdminToken] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const linkTo = useLinkTo();
  const colorScheme = useColorScheme();

  const { loading, error, data } = useQuery(LOCATIONS_QUERY, {
    variables: { token },
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const swipeAction = await AsyncStorage.getItem("orderItemSwipe");
        setSwipeAction(swipeAction)
      } catch (ex) {}

      try {
        const locationId = await AsyncStorage.getItem("location");
        if (locationId) {
          setLocationId(locationId);
        }
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}

      try {
        const storedRole = await AsyncStorage.getItem("role");
        setRole(storedRole);
      } catch (ex) {}

      try {
        const storedAdminToken = await AsyncStorage.getItem("adminToken");
        setAdminToken(storedAdminToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const [errorMessage, setErrorMessage] = useState("");

  const [login] = useMutation(LOGIN);

  async function tryLogin(username, password) {
    const result = await login({
      variables: {
        username,
        password,
      },
    });

    if (result && result.data && result.data.login) {
      setUsername("");
      setPassword("");
      if (result.data.login.adminToken) {
        try {
          await AsyncStorage.setItem(
            "adminToken",
            result.data.login.adminToken
          );
          setAdminToken(result.data.login.adminToken);
        } catch (ex) {}
      }
      if (result.data.login.apiToken) {
        try {
          await AsyncStorage.setItem("token", result.data.login.apiToken);
          setToken(result.data.login.apiToken);
        } catch (ex) {}
      }

      if (result.data.login.token) {
        try {
          await AsyncStorage.setItem("operatorId", result.data.login.token);
          setOperatorId(result.data.login.token);
        } catch (ex) {}
      }

      if (result.data.login.role) {
        try {
          await AsyncStorage.setItem("role", result.data.login.role);
          setRole(result.data.login.role);
        } catch (ex) {}
      }
      setErrorMessage("");
    } else {
      setErrorMessage("Check your credentials and try again");
    }
    console.log(result);
  }

  return (
    <ScrollView>
      <View style={styles.container}>
        {token ? (
          <View style={styles.menuButton}>
            <Text style={styles.text}>Location:</Text>
            <Picker
              selectedValue={locationId}
              onValueChange={async (value) => {
                try {
                  await AsyncStorage.setItem("location", value);
                  setLocationId(value);
                  window.location.reload()
                } catch (ex) {}
              }}
            >
              <Picker.Item label="All" value="All"></Picker.Item>
              {data &&
                data.locations &&
                data.locations.map((l) => (
                  <Picker.Item
                    key={l.id}
                    label={l.name + " " + l.city}
                    value={l.id}
                  ></Picker.Item>
                ))}
            </Picker>
          </View>
        ) : null}
          <View style={styles.menuButton}>
            <Text style={styles.text}>Swipe action:</Text>
            <Picker
              selectedValue={swipeAction}
              onValueChange={async (value) => {
                try {
                  await AsyncStorage.setItem("orderItemSwipe", value);
                  setSwipeAction(value);
                  window.location.reload()
                } catch (ex) {}
              }}
            >
              <Picker.Item label="Deliver" value="Deliver"></Picker.Item>
              <Picker.Item label="Delete" value="Delete"></Picker.Item>
            </Picker>
          </View>
        {token ? (
          <Pressable
            style={styles.menuButton}
            onPress={() => {
              linkTo("/tables/operator-settings/lights&colors");
            }}
          >
            <Text style={styles.text}>Lights & colors</Text>
          </Pressable>
        ) : null}
        {adminToken ? (
          <Pressable
            style={styles.menuButton}
            onPress={() => {
              linkTo("/tables/operator-settings/summary");
            }}
          >
            <Text style={styles.text}>Summary</Text>
          </Pressable>
        ) : null}
        {adminToken ? (
          <Pressable
            style={styles.menuButton}
            onPress={() => {
              linkTo("/tables/operator-settings/report");
            }}
          >
            <Text style={styles.text}>Report</Text>
          </Pressable>
        ) : null}
        {role === "admin" && adminToken ? (
          <Pressable
            style={styles.menuButton}
            onPress={() => {
              linkTo("/tables/operator-settings/invite");
            }}
          >
            <Text style={styles.text}>Invite</Text>
          </Pressable>
        ) : null}
        {token ? (
          <Pressable
            style={styles.menuButton}
            onPress={async () => {
              try {
                await AsyncStorage.removeItem("operatorId");
                setOperatorId(null);
              } catch (ex) {}

              try {
                await AsyncStorage.removeItem("token");
                setToken(null);
              } catch (ex) {}

              try {
                await AsyncStorage.removeItem("adminToken");
                setAdminToken(null);
              } catch (ex) {}

              try {
                await AsyncStorage.removeItem("location");
                setLocationId(null);
              } catch (ex) {}
            }}
          >
            <Text style={styles.text}>Logout</Text>
          </Pressable>
        ) : null}

        {!token ? (
          <View style={{ flex: 0.5, maxWidth: 400, justifyContent: "center" }}>
            <View>
              <TextInput
                onChangeText={(text) => {
                  setUsername(text);
                }}
                onSubmitEditing={async (e) => {
                  await tryLogin(e.nativeEvent.text, password);
                }}
                placeholder="Username"
                defaultValue={""}
                style={{
                  margin: 15,
                  padding: 15,
                  borderRadius: 12,
                  color: colorScheme === "dark" ? "white" : "black",
                  fontFamily: Config.theme.defaultFontFamily,
                  fontSize: 24,
                }}
              />
            </View>
            <View>
              <TextInput
                onChangeText={(text) => {
                  setPassword(text);
                }}
                onSubmitEditing={async (e) => {
                  await tryLogin(username, e.nativeEvent.text);
                }}
                secureTextEntry={true}
                placeholder="Password"
                defaultValue={""}
                style={{
                  margin: 15,
                  padding: 15,
                  borderRadius: 12,
                  color: colorScheme === "dark" ? "white" : "black",
                  fontFamily: Config.theme.defaultFontFamily,
                  fontSize: 24,
                }}
              />
            </View>
            <Pressable
              style={styles.menuButton}
              onPress={async () => {
                await tryLogin(username, password);
              }}
            >
              <Text style={styles.text}>Login</Text>
            </Pressable>
            {errorMessage && (
              <Text style={[styles.text, { color: "red" }]}>
                {errorMessage}
              </Text>
            )}
          </View>
        ) : null}
        <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
  lightThemeColors: {},
  darkThemeColors: {
    borderColor: "white",
    color: "white",
  },
  text: {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: "bold",
    letterSpacing: 0.25,
    color: "black",
  },
  container: {
    flex: 1,
    padding: 20,
    alignItems: "stretch",
    justifyContent: "center",
  },
  menuButton: {
    alignItems: "center",
    justifyContent: "center",
    elevation: 3,
    margin: 20,
    padding: 20,
    backgroundColor: "white",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
